const copyToClipboard = (text) => {
  const element = document.createElement('input');
  document.body.append(element);
  element.setAttribute('value', text);
  element.select();
  document.execCommand('copy');
  element.remove();
};

const { localStorage } = window;

export function setCartId(id) {
  return localStorage.setItem('cartId', id);
}

export function getCartId() {
  return localStorage.getItem('cartId');
}

export function setOrderSessionId(id) {
  return localStorage.setItem('orderSessionId', id);
}

export function getOrderSessionId() {
  return localStorage.getItem('orderSessionId');
}

export function setCartItems(cartItems) {
  return localStorage.setItem('guestUserCartItems', JSON.stringify(cartItems));
}

export function getCartItems() {
  return JSON.parse(localStorage.getItem('guestUserCartItems'));
}

export function setCustomCartItems(customCartItems) {
  return localStorage.setItem('guestUserCustomCartItems', JSON.stringify(customCartItems));
}

export function getCustomCartItems() {
  return JSON.parse(localStorage.getItem('guestUserCustomCartItems'));
}

export function setAddress(address) {
  return localStorage.setItem('address', JSON.stringify(address));
}

export function getAddress() {
  return JSON.parse(localStorage.getItem('address'));
}

export function setGuestUserAddresses(guestUserAddresses) {
  return localStorage.setItem('guestUserAddresses', JSON.stringify(guestUserAddresses));
}

export function getGuestUserAddresses() {
  return JSON.parse(localStorage.getItem('guestUserAddresses'));
}

export function setGuestUserSessionExpireTime(expiryTime) {
  return localStorage.setItem('getGuestUserSessionExpireTime', JSON.stringify(expiryTime));
}

export function getGuestUserSessionExpireTime() {
  return JSON.parse(localStorage.getItem('getGuestUserSessionExpireTime'));
}

export function getToken() {
  return localStorage.getItem('token');
}

export function setToken(token) {
  localStorage.setItem('token', token);
}

export function getRefreshToken() {
  return localStorage.getItem('refresh_token');
}

export function setRefreshToken(token) {
  localStorage.setItem('refresh_token', token);
}

export function getAccessToken() {
  return localStorage.getItem('access_token');
}

export function setAccessToken(token) {
  localStorage.setItem('access_token', token);
}

export function getLastAccessTokenUpdatedAtEpoch() {
  return JSON.parse(localStorage.getItem('access_token_updated_at'));
}

export function setLastAccessTokenUpdatedAtEpoch(lastAccessTokenUpdatedAt) {
  localStorage.setItem('access_token_updated_at', JSON.stringify(lastAccessTokenUpdatedAt));
}

export function setUUID(uuid) {
  localStorage.setItem('uuid', uuid);
}

export function getUUID() {
  return localStorage.getItem('uuid');
}

export function removeAuthentication() {
  localStorage.removeItem('token');
  localStorage.removeItem('uuid');
  localStorage.removeItem('profile');
  localStorage.removeItem('address');
  localStorage.removeItem('cartId');
  localStorage.removeItem('orderSessionId');
  localStorage.removeItem('fcm-token');
  localStorage.removeItem('variantCoachmarkFrequencyCount');
  localStorage.removeItem('lastTimeVariantCoachmarkShown');
  localStorage.removeItem('rateOrder');
  localStorage.removeItem('language');
  localStorage.removeItem('access_token_updated_at');
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
}

export function getFCMToken() {
  return window.localStorage.getItem('fcm-token');
}

export function setFCMToken(fcmToken) {
  copyToClipboard(fcmToken);
  window.localStorage.setItem('fcm-token', fcmToken);
}

export function removeFCMToken() {
  window.localStorage.removeItem('fcm-token');
}

export function setProfile(profile) {
  localStorage.setItem('profile', JSON.stringify(profile));
}

export function getProfile() {
  return JSON.parse(localStorage.getItem('profile'));
}

export function setHideSellOnLoveLocal(status) {
  return localStorage.setItem('hideSellOnLoveLocalCard', status);
}

export function getHideSellOnLoveLocal() {
  return JSON.parse(localStorage.getItem('hideSellOnLoveLocalCard'));
}

export function getLastOrderBreachedInteractionTime() {
  const lastOrderBreachedInteraction = localStorage.getItem('lastOrderBreachedInteraction');
  const defaultRemoteConfig = window.firebase.remoteConfig();
  let orderTATBreached = null;
  if (defaultRemoteConfig) {
    orderTATBreached = defaultRemoteConfig.getValue('order_tat_breached').asNumber();
  } else {
    return false;
  }
  if (!lastOrderBreachedInteraction || !orderTATBreached) {
    return true;
  }
  const date2 = new Date(JSON.parse(lastOrderBreachedInteraction));
  const date1 = new Date();
  const diffHr = Math.floor((date1.getTime() - date2.getTime()) / (1000 * 3600));
  if (diffHr > orderTATBreached) {
    return true;
  }
  return false;
}

export function getReferralMeta() {
  const defaultRemoteConfig = window.firebase.remoteConfig();
  let referralMeta = null;
  if (defaultRemoteConfig) {
    referralMeta = defaultRemoteConfig.getValue('refer_earn_meta').asString();
  }
  if (!referralMeta) {
    return null;
  }
  if (typeof referralMeta === 'string') {
    return JSON.parse(referralMeta);
  }
  return referralMeta;
}

export function getPlatFormFeeSubText() {
  const defaultRemoteConfig = window.firebase.remoteConfig();
  const data = defaultRemoteConfig.getValue('platform_fee_sub_text').asString();
  return data;
}

export function setLastOrderBreachedInteractionTime(currentTime) {
  localStorage.setItem('lastOrderBreachedInteraction', JSON.stringify(currentTime));
}

export function getVariantCoachmarkFrequencyCount() {
  return JSON.parse(localStorage.getItem('variantCoachmarkFrequencyCount'));
}

export function getLastTimeVariantCoachmarkShown() {
  return JSON.parse(localStorage.getItem('lastTimeVariantCoachmarkShown'));
}

export function setLanguage(language) {
  localStorage.setItem('language', language);
}

export function getLanguage() {
  return localStorage.getItem('language');
}

export function setRateOrder(details) {
  localStorage.setItem('rateOrder', JSON.stringify(details));
}

export function getRateOrder() {
  return JSON.parse(localStorage.getItem('rateOrder'));
}

export function setCoachmark(coachmark) {
  return localStorage.setItem('coachmark', JSON.stringify(coachmark));
}

export function getCoachmark() {
  return JSON.parse(localStorage.getItem('coachmark'));
}

export function setLastOfferAlert(date) {
  localStorage.setItem('lastOfferAlert', date);
}

export function getLastOfferAlert() {
  return localStorage.getItem('lastOfferAlert');
}

export function setDeviceId(id) {
  localStorage.setItem('deviceId', id);
}

export function getDeviceId() {
  return localStorage.getItem('deviceId');
}

export function setCloseDefaultGuestLocation(date) {
  return localStorage.setItem('closeDefaultGuestLocation', date);
}

export function getCloseDefaultGuestLocation() {
  return localStorage.getItem('closeDefaultGuestLocation');
}

export function setCloseOrderTracker(date) {
  return sessionStorage.setItem('closeOrderTracker', date);
}

export function getCloseOrderTracker() {
  return sessionStorage.getItem('closeOrderTracker');
}
