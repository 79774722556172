import { Storage } from '../app/utilities';

export function initFreshchat() {
  const customer = Storage.getProfile();
  const config = {
    token: 'e9d6f17c-3fe5-4328-a10e-adfd1231cbbc',
    host: 'https://wchat.freshchat.com',
    config: {
      headerProperty: {
        hideChatButton: true,
        appName: 'Support',
        backgroungImage: 'none',
      },
    },
    externalId: Storage.getUUID(),
  };
  const script = document.createElement('script');
  const init = () => {
    window.fcWidget.init(config);
    window.fcWidget.user.setProperties({
      firstName: customer.first_name || 'LoveLocal User',
      lastName: customer.last_name,
      email: customer.email,
      phone: customer.phone_number,
    }).then(() => {}).catch(() => {});
  };
  if (document.getElementById('freshchat-js-sdk')) {
    init();
  } else {
    script.id = 'freshchat-js-sdk';
    script.async = true;
    script.src = 'https://wchat.freshchat.com/js/widget.js';
    script.onload = init;
    document.head.appendChild(script);
  }
  if (window.addEventListener) {
    window.addEventListener('load', initFreshchat, !1);
  } else {
    window.attachEvent('load', initFreshchat, !1);
  }
}

export function open() {
  window.fcWidget.open();
}

export function clearUser() {
  window.fcWidget.user.clear().then(() => {}).catch(() => {});
}

export function updateProfile() {
  const customer = Storage.getProfile();
  window.fcWidget.user.update({
    firstName: customer.first_name,
    lastName: customer.last_name,
    email: customer.email,
    phone: customer.phone_number,
    externalId: Storage.getUUID(),
  }).then(() => {}).catch(() => {});
}
