import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Spinner, Row,
} from 'react-bootstrap';
import StoreShimmer from './store/StoreShimmer';
import LineShimmer from './individual-layout/LineShimmer';
import FallbackShimmer from './individual-layout/FallbackShimmer';
import '../styles/shimmer/Shimmer.scss';
import ShopsTabShimmer from './Stores/ShopsTabShimmer';
import StoreCardsLayout from './Stores/StoreCardsLayout';

const Shimmer = (props) => {
  const { shimmerType } = props;
  let shimmerJSX = (
    <Row
      className="justify-content-center"
    >
      <Spinner
        animation="border"
        variant="primary"
      />
    </Row>
  );
  if (shimmerType) {
    switch (shimmerType) {
      case 'FALLBACK':
        shimmerJSX = (
          <FallbackShimmer />
        );
        break;

      case 'SHOP_DETAILS':
        shimmerJSX = (
          <StoreShimmer />
        );
        break;

      case 'LINE_SHIMMER':
        shimmerJSX = (
          <LineShimmer />
        );
        break;

      case 'SHOPS_TAB':
        shimmerJSX = (
          <ShopsTabShimmer />
        );
        break;

      case 'STORE_CARDS_LAYOUT':
        shimmerJSX = (
          <StoreCardsLayout />
        );
        break;

      default:
        break;
    }
  }
  return (
    <Container
      fluid={shimmerType === 'FALLBACK'}
      className="wrapper px-0"
    >
      {shimmerJSX}
    </Container>
  );
};

Shimmer.propTypes = {
  shimmerType: PropTypes.string,
};

Shimmer.defaultProps = {
  shimmerType: '',
};

export default Shimmer;
