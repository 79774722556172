import React, { useState } from 'react';
import PropTypes from 'prop-types';

const loaderAnim = require('../common/images/ll-image-loader-anim.gif');
const defaultCustomPlaceHolder = require('../common/images/default-custom-placeholder.png');
const defaultCustomItemPlaceHolder = require('../common/images/default-custom-item-placeholder.png');
const defaultOffersPlaceHolder = require('../common/images/default-offers-placeholder.png');
const defaultProductPlaceHolder = require('../common/images/default-product-placeholder.png');

const errorImageFallback = (event, fallbackImageSrc) => {
  // eslint-disable-next-line no-param-reassign
  event.target.src = fallbackImageSrc;
};

const CustomImage = (props) => {
  const {
    imageType, imageUrl, imageStyle, imageAlt, imageTitle,
  } = props;
  const [isImageLoading, setImageLoadStatus] = useState(true);
  let fallbackImage = defaultCustomPlaceHolder;
  switch (imageType) {
    case 'PRODUCT':
      fallbackImage = defaultProductPlaceHolder;
      break;

    case 'OFFER':
      fallbackImage = defaultOffersPlaceHolder;
      break;

    case 'CUSTOM_OFFER':
      fallbackImage = defaultCustomPlaceHolder;
      break;

    case 'CATEGORY':
      fallbackImage = defaultProductPlaceHolder;
      break;

    case 'CUSTOM_ITEM':
      fallbackImage = defaultCustomItemPlaceHolder;
      break;

    default:
      break;
  }

  return (
    <img
      className={imageStyle || ''}
      alt={imageAlt || ''}
      title={imageTitle || ''}
      src={isImageLoading ? loaderAnim : imageUrl}
      onError={(event) => {
        errorImageFallback(event, fallbackImage);
      }}
      onLoad={() => {
        setImageLoadStatus(false);
      }}
    />
  );
};

CustomImage.propTypes = {
  imageType: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageStyle: PropTypes.string,
  imageAlt: PropTypes.string,
  imageTitle: PropTypes.string,
};

CustomImage.defaultProps = {
  imageAlt: '',
  imageStyle: '',
  imageTitle: '',
};

export default CustomImage;
