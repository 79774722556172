import ApplicableValueOffers from 'app/layout/checkout/ApplicableValueOffers';
import PropTypes from 'prop-types';
import { Constants } from 'app/utilities';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { logValueOfferSeeAllClicked } from 'clevertap/LogEvent';
import CartSpendOffer from '../../CartSpendOffer';
import * as screens from '../../../../events/screens';
import RewardCoupon from 'app/pages/offers/RewardCoupon';

function ShopOffers(props) {
  const {
    spendAndValueOffers, isMobile, history,
    toggleSideOverlay, language,
    cartDetails, handleSpendOffer,
  } = props;

  const appliedValueOffer = (
    cartDetails && cartDetails.cartOffers.length > 0
  ) ? cartDetails.cartOffers[0] : null;

  return (
    <>
      <Row
        className="align-items-center"
      >
        <Col
          className="fs-5 d-flex align-items-center"
        >
          <span
            className="font-weight-black"
          >
            Shop Offers
          </span>
          &nbsp;&nbsp;
          <span>
            {` (${spendAndValueOffers.length} Offers)`}
          </span>
        </Col>
        {
          (
            (spendAndValueOffers.length > 1)
          ) && (
            <Col
              xs="auto"
              className="fs-6 text-primary font-weight-bold d-flex align-items-center cursor-pointer"
              onClick={() => {
                logValueOfferSeeAllClicked(
                  { Page: 'Checkout' },
                );
                if (isMobile) {
                  history.push(
                    '/checkout/applicable-value-offers',
                    { applicableOffers: spendAndValueOffers },
                  );
                } else if (toggleSideOverlay) {
                  toggleSideOverlay(() => (<RewardCoupon {...props} />), false);
                }
              }}
            >
              {Constants.String.SEE_ALL[language].toUpperCase()}
            </Col>
          )
        }
      </Row>
      <Row
        className="py-3 px-4"
      >
        {
            appliedValueOffer ? (
              <CartSpendOffer
                item={{
                  ...appliedValueOffer,
                  discountValue: appliedValueOffer.discountValue,
                  appliedStatus: true,
                  isApplicable: true,
                  id: appliedValueOffer.id,
                  image: appliedValueOffer.image || '',
                  store: {
                    code: cartDetails.store.id,
                    displayName: cartDetails.store.name,
                    locality: cartDetails.store.locality,
                    city: cartDetails.store.city,
                    country: 'india',
                    storeType: {
                      displayName: cartDetails.store.type,
                    },
                  },
                }}
                language={language}
                history={history}
                handleSpendOffer={(apply, offer) => {
                  handleSpendOffer(
                    apply,
                    offer,
                  );
                }}
                screen={screens.CHECKOUT}
              />
            ) : (
              <CartSpendOffer
                key={spendAndValueOffers[0].offerId}
                item={{
                  ...spendAndValueOffers[0],
                  appliedStatus: false,
                  discountValue: spendAndValueOffers[0].discountAmount,
                  image: spendAndValueOffers[0].images[0] || '',
                  id: spendAndValueOffers[0].offerId,
                  isApplicable: spendAndValueOffers[0].isValid,
                  store: {
                    code: cartDetails.store.id,
                    displayName: cartDetails.store.name,
                    locality: cartDetails.store.locality,
                    city: cartDetails.store.city,
                    country: 'india',
                    storeType: {
                      displayName: cartDetails.store.type,
                    },
                  },
                }}
                language={language}
                history={history}
                handleSpendOffer={(apply, offer) => {
                  handleSpendOffer(
                    apply,
                    offer,
                  );
                }}
                screen={screens.CHECKOUT}
              />
            )
          }
      </Row>
    </>
  );
}

ShopOffers.propTypes = {
  handleSpendOffer: PropTypes.func.isRequired,
  spendAndValueOffers: PropTypes.arrayOf(PropTypes.shape({
    offerId: PropTypes.number,
    discountAmount: PropTypes.number,
    images: PropTypes.string,
    isValid: PropTypes.bool,
  })).isRequired,
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  cartDetails: PropTypes.shape({
    cartOffers: PropTypes.arrayOf(PropTypes.shape({})),
    store: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      minDeliveryPenalty: PropTypes.number,
      locality: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      type: PropTypes.string,
    }),
  }).isRequired,
};

export default ShopOffers;
