import * as ActionTypes from './ActionTypes';

export const addAddress = (address, onSuccess, onFailure) => ({
  type: ActionTypes.ADD_ADDRESS_REQUEST,
  payload: {
    address,
    onSuccess,
    onFailure,
  },
});

export const addAddressSuccess = (data) => ({
  type: ActionTypes.ADD_ADDRESS_SUCCESS,
  payload: {
    address: data.res,
  },
});

export const addAddressFailure = () => ({
  type: ActionTypes.ADD_ADDRESS_FAILURE,
});

export const addressLoadRequest = (onSuccess, onFailure) => ({
  type: ActionTypes.ADDRESS_LOAD_REQUEST,
  payload: {
    onSuccess,
    onFailure,
  },
});

export const addressLoadSuccess = (data) => ({
  type: ActionTypes.ADDRESS_LOAD_SUCCESS,
  payload: {
    addresses: data.res,
  },
});

export const addressLoadFailure = () => ({
  type: ActionTypes.ADDRESS_LOAD_FAILURE,
});

export const recentAddressLoadRequest = (onSuccess, onFailure) => ({
  type: ActionTypes.RECENT_ADDRESS_REQUEST,
  payload: {
    onSuccess,
    onFailure,
  },
});

export const recentAddressLoadSuccess = (data) => ({
  type: ActionTypes.RECENT_ADDRESS_SUCCESS,
  payload: {
    addresses: data.res,
  },
});

export const recentAddressLoadFailure = () => ({
  type: ActionTypes.RECENT_ADDRESS_FAILURE,
});

export const addressChangeRequest = (address, onSuccess) => ({
  type: ActionTypes.ADDRESS_CHANGE_REQUEST,
  payload: {
    address,
    onSuccess,
  },
});

export const addressChangeSuccess = (data) => ({
  type: ActionTypes.ADDRESS_CHANGE_SUCCESS,
  payload: {
    address: data.res,
  },
});

export const addressChangeFailure = () => ({
  type: ActionTypes.ADDRESS_CHANGE_FAILURE,
});

export const addressDeleteRequest = (address, onSuccess) => ({
  type: ActionTypes.ADDRESS_DELETE_REQUEST,
  payload: {
    address,
    onSuccess,
  },
});

export const addressDeleteSuccess = (data) => ({
  type: ActionTypes.ADDRESS_DELETE_SUCCESS,
  payload: {
    address: data.res,
  },
});

export const addressDeleteFailure = () => ({
  type: ActionTypes.ADDRESS_DELETE_FAILURE,
});

export const addressEditRequest = (address, onSuccess) => ({
  type: ActionTypes.ADDRESS_EDIT_REQUEST,
  payload: {
    address,
    onSuccess,
  },
});

export const addressEditSuccess = (data) => ({
  type: ActionTypes.ADDRESS_EDIT_SUCCESS,
  payload: {
    address: data.res,
  },
});

export const addressEditFailure = () => ({
  type: ActionTypes.ADDRESS_EDIT_FAILURE,
});

export const appExOfferLoadRequest = (
  offset, limit, onSuccess, onFailure, cancelToken, storeId,
) => ({
  type: ActionTypes.APPEX_OFFER_LOAD_REQUEST,
  payload: {
    limit,
    offset,
    onSuccess,
    onFailure,
    cancelToken,
    storeId,
  },
});

export const appExOfferLoadSuccess = (data) => ({
  type: ActionTypes.APPEX_OFFER_LOAD_SUCCESS,
  payload: {
    appExOffers: data.res,
  },
});

export const appExOfferLoadFailure = () => ({
  type: ActionTypes.APPEX_OFFER_LOAD_FAILURE,
});

export const applyRewardRequest = (scratchCardId, apply, onSuccess, onFailure) => ({
  type: ActionTypes.APPLY_REWARD_REQUEST,
  payload: {
    scratchCardId,
    apply,
    onSuccess,
    onFailure,
  },
});

export const applyRewardSuccess = (data) => ({
  type: ActionTypes.APPLY_REWARD_SUCCESS,
  payload: {
    cartDetails: data.res,
  },
});

export const applyRewardFailure = () => ({
  type: ActionTypes.APPLY_REWARD_FAILURE,
});

export const bogoOffersLoadRequest = (offset, limit, onSuccess, cancelToken, storeId) => ({
  type: ActionTypes.BOGO_OFFERS_LOAD_REQUEST,
  payload: {
    offset,
    limit,
    onSuccess,
    cancelToken,
    storeId,
  },
});

export const bogoOffersLoadSuccess = (data) => ({
  type: ActionTypes.BOGO_OFFERS_LOAD_SUCCESS,
  payload: {
    bogoOffers: data.res,
    offset: data.offset,
  },
});

export const bogoOffersLoadFailure = () => ({
  type: ActionTypes.BOGO_OFFERS_LOAD_FAILURE,
});

export const cartAvailableOffersRequest = (offset, limit, onSuccess, cancelToken, storeId) => ({
  type: ActionTypes.CART_AVAILABLE_OFFERS_REQUEST,
  payload: {
    offset,
    limit,
    onSuccess,
    cancelToken,
    storeId,
  },
});

export const cartAvailableOffersSuccess = (data) => ({
  type: ActionTypes.CART_AVAILABLE_OFFERS_SUCCESS,
  payload: {
    cartAvailableOffers: data.res,
    offset: data.offset,
  },
});

export const cartAvailableOffersFailure = () => ({
  type: ActionTypes.CART_AVAILABLE_OFFERS_FAILURE,
});

export const cartBulkAddRequest = (guestUserCartItems, onSuccess, onFailure) => ({
  type: ActionTypes.CART_BULK_ADD_REQUEST,
  payload: {
    data: {
      guestUserCartItems,
    },
    onSuccess,
    onFailure,
  },
});

export const cartBulkAddSuccess = (data) => ({
  type: ActionTypes.CART_BULK_ADD_SUCCESS,
  payload: {
    cartDetails: data.res,
  },
});

export const cartBulkAddFailure = () => ({
  type: ActionTypes.CART_BULK_ADD_FAILURE,
});

export const cartAddRequest = (
  storeId, productId, quantity, onSuccess, onFailure, productInfo = {}, forceAdd = false,
) => ({
  type: ActionTypes.CART_ADD_REQUEST,
  payload: {
    data: {
      action: 'PATCH_PRODUCT',
      product: {
        storeId,
        productId,
        quantity,
        productInfo,
      },
      forceAdd,
    },
    onSuccess,
    onFailure,
  },
});

export const cartAddSuccess = (data) => ({
  type: ActionTypes.CART_ADD_SUCCESS,
  payload: {
    product: data.product,
    cartDetails: data.res,
    forceAdd: data.forceAdd,
  },
});

export const cartAddFailure = (data) => ({
  type: ActionTypes.CART_ADD_FAILURE,
  payload: {
    productId: data.productId,
    multiStoreCheckoutErrorMsg: data.errorMsg,
    product: data.product,
    productInfo: data.productInfo,
  },
});

export const cartLoadRequest = (params = {}, onSuccess, onFailure) => ({
  type: ActionTypes.CART_LOAD_REQUEST,
  payload: {
    onSuccess,
    onFailure,
    params,
  },
});

export const cartLoadSuccess = (data) => ({
  type: ActionTypes.CART_LOAD_SUCCESS,
  payload: {
    cartDetails: data.res,
  },
});

export const cartLoadFailure = () => ({
  type: ActionTypes.CART_LOAD_FAILURE,
});

export const cartIdLoadRequest = (onSuccess, onFailure) => ({
  type: ActionTypes.CART_ID_LOAD_REQUEST,
  payload: {
    onSuccess,
    onFailure,
  },
});

export const cartIdLoadSuccess = (data) => ({
  type: ActionTypes.CART_ID_LOAD_SUCCESS,
  payload: {
    cartId: data.res.cartId,
    orderSessionId: data.res.orderSessionId,
  },
});

export const cartIdLoadFailure = () => ({
  type: ActionTypes.CART_ID_LOAD_FAILURE,
});

export const changeLanguage = (language) => ({
  type: ActionTypes.CHANGE_LANGUAGE,
  payload: {
    language,
  },
});

export const clearSelectedAddress = () => ({
  type: ActionTypes.CLEAR_SELECTED_ADDRESS,
});

export const couponCodeRequest = (couponCode, onSuccess, onFailure) => ({
  type: ActionTypes.COUPON_CODE_REQUEST,
  payload: {
    couponCode,
    onSuccess,
    onFailure,
  },
});

export const couponCodeSuccess = (data) => ({
  type: ActionTypes.COUPON_CODE_SUCCESS,
  payload: {
    cartDetails: data.res,
  },
});

export const couponCodeFailure = () => ({
  type: ActionTypes.COUPON_CODE_FAILURE,
});

export const couponsLoadRequest = (params, onSuccess, cancelToken) => ({
  type: ActionTypes.COUPONS_LOAD_REQUEST,
  payload: {
    onSuccess,
    cancelToken,
    params,
  },
});

export const couponsLoadSuccess = (data) => ({
  type: ActionTypes.COUPONS_LOAD_SUCCESS,
  payload: {
    coupons: data.res,
    offset: data.offset,
  },
});

export const couponsLoadFailure = () => ({
  type: ActionTypes.COUPONS_LOAD_FAILURE,
});

export const customerStatRequest = (cancelToken) => ({
  type: ActionTypes.CUSTOMER_STAT_REQUEST,
  payload: {
    cancelToken,
  },
});

export const customerStatSuccess = (data) => ({
  type: ActionTypes.CUSTOMER_STAT_SUCCESS,
  payload: {
    customerStat: data.res,
  },
});

export const customerStatFailure = () => ({
  type: ActionTypes.CUSTOMER_STAT_FAILURE,
});

export const deliveryPreferenceRequest = (deliveryPreference, onSuccess, onFailure) => ({
  type: ActionTypes.DELIVERY_PREFERENCE_PATCH_REQUEST,
  payload: {
    data: {
      action: 'PATCH_DELIVERY_PREFERENCE',
      body: {
        deliveryPreference,
      },
    },
    onSuccess,
    onFailure,
  },
});

export const deliveryPreferenceSuccess = (data) => ({
  type: ActionTypes.DELIVERY_PREFERENCE_PATCH_SUCCESS,
  payload: {
    cartDetails: data.res,
  },
});

export const deliveryPreferenceFailure = () => ({
  type: ActionTypes.DELIVERY_PREFERENCE_PATCH_FAILURE,
});

export const heroBannerRequest = (cancelToken) => ({
  type: ActionTypes.HERO_BANNER_REQUEST,
  payload: {
    cancelToken,
  },
});

export const heroBannerSuccess = (data) => ({
  type: ActionTypes.HERO_BANNER_SUCCESS,
  payload: {
    heroBanner: data.res,
  },
});

export const heroBannerFailure = () => ({
  type: ActionTypes.HERO_BANNER_FAILURE,
});

export const brandBannersRequest = (cancelToken) => ({
  type: ActionTypes.BRAND_BANNERS_REQUEST,
  payload: {
    cancelToken,
  },
});

export const brandBannersSuccess = (data) => ({
  type: ActionTypes.BRAND_BANNERS_SUCCESS,
  payload: {
    brandBanners: data.res,
  },
});

export const brandBannersFailure = () => ({
  type: ActionTypes.BRAND_BANNERS_FAILURE,
});

export const homeBanners = (cancelToken) => ({
  type: ActionTypes.HOME_BANNERS_REQUEST,
  payload: {
    cancelToken,
  },
});

export const homeBannersSuccess = (data) => ({
  type: ActionTypes.HOME_BANNERS_SUCCESS,
  payload: {
    homeBanners: data.res,
  },
});

export const homeBannersFailure = () => ({
  type: ActionTypes.HOME_BANNERS_FAILURE,
});

export const homeTopBanners = (cancelToken) => ({
  type: ActionTypes.HOME_TOP_BANNERS_REQUEST,
  payload: {
    cancelToken,
  },
});

export const homeTopBannersSuccess = (data) => ({
  type: ActionTypes.HOME_TOP_BANNERS_SUCCESS,
  payload: {
    homeTopBanners: data.res,
  },
});

export const homeTopBannersFailure = () => ({
  type: ActionTypes.HOME_TOP_BANNERS_FAILURE,
});

export const initialLoad = (cancelToken, onSuccess) => ({
  type: ActionTypes.INITIAL_LOAD,
  payload: {
    cancelToken,
    onSuccess,
  },
});

export const storesSpecificCouponsRequest = (
  offset, limit, onSuccess, cancelToken,
  filterFlag, city, locality, storeTypes,
) => ({
  type: ActionTypes.STORES_SPECIFIC_COUPON_REQUEST,
  payload: {
    offset,
    limit,
    onSuccess,
    cancelToken,
    filterFlag,
    city,
    locality,
    storeTypes,
  },
});

export const storesSpecificCouponsSuccess = (data) => ({
  type: ActionTypes.STORES_SPECIFIC_COUPON_SUCCESS,
  payload: {
    storeCoupons: data.res,
    offset: data.offset,
  },
});

export const storesSpecificCouponsFailure = () => ({
  type: ActionTypes.STORES_SPECIFIC_COUPON_FAILURE,
});

export const logout = () => ({
  type: ActionTypes.LOGOUT,
});

export const newlyAddedStoresRequest = (
  cancelToken, limit, offset, filterFlag, city, locality, storeTypes,
) => ({
  type: ActionTypes.NEWLY_ADDED_STORES_REQUEST,
  payload: {
    cancelToken,
    filterFlag,
    city,
    locality,
    storeTypes,
    limit,
    offset,
  },
});

export const newlyAddedStoresSuccess = (data) => ({
  type: ActionTypes.NEWLY_ADDED_STORES_SUCCESS,
  payload: {
    newlyAddedStores: data.res,
  },
});

export const newlyAddedStoresFailure = () => ({
  type: ActionTypes.NEWLY_ADDED_STORES_FAILURE,
});

export const premiumStoresRequest = (
  cancelToken, limit, offset, params = {}, filters = [], isFreeSample,
) => ({
  type: ActionTypes.PREMIUM_STORES_REQUEST,
  payload: {
    cancelToken,
    limit,
    offset,
    params,
    filters,
    isFreeSample,
  },
});

export const premiumStoresSuccess = (data) => ({
  type: ActionTypes.PREMIUM_STORES_SUCCESS,
  payload: {
    premiumStores: data.res,
  },
});

export const premiumStoresFailure = () => ({
  type: ActionTypes.PREMIUM_STORES_FAILURE,
});

export const storeNewCategoryRequest = (
  cancelToken,
) => ({
  type: ActionTypes.STORE_NEW_CATEGORY_REQUEST,
  payload: {
    cancelToken,
  },
});

export const storeNewCategorySuccess = (data) => ({
  type: ActionTypes.STORE_NEW_CATEGORY_SUCCESS,
  payload: {
    storeNewCategory: data.res,
  },
});

export const storeNewCategoryFaliure = () => ({
  type: ActionTypes.STORE_NEW_CATEGORY_FAILURE,
});

export const notificationsLoadRequest = (offset, limit, onSuccess, cancelToken) => ({
  type: ActionTypes.NOTIFICATIONS_LOAD_REQUEST,
  payload: {
    offset,
    limit,
    onSuccess,
    cancelToken,
  },
});

export const notificationsLoadSuccess = (data) => ({
  type: ActionTypes.NOTIFICATIONS_LOAD_SUCCESS,
  payload: {
    notifications: data.res,
    offset: data.offset,
  },
});

export const notificationsLoadFailure = () => ({
  type: ActionTypes.NOTIFICATIONS_LOAD_FAILURE,
});

export const offersLoadRequest = (offset, limit, onSuccess, cancelToken, storeId) => ({
  type: ActionTypes.OFFERS_LOAD_REQUEST,
  payload: {
    offset,
    limit,
    onSuccess,
    cancelToken,
    storeId,
  },
});

export const offersLoadSuccess = (data) => ({
  type: ActionTypes.OFFERS_LOAD_SUCCESS,
  payload: {
    offers: data.res,
    offset: data.offset,
  },
});

export const offersLoadFailure = () => ({
  type: ActionTypes.OFFERS_LOAD_FAILURE,
});

export const onlineProductsRequest = (
  offset, limit, searchText, categoryId,
  storeId, onSuccess, cancelToken,
) => ({
  type: ActionTypes.ONLINE_PRODUCTS_REQUEST,
  payload: {
    offset,
    limit,
    searchText,
    categoryId,
    storeId,
    onSuccess,
    cancelToken,
  },
});

export const onlineProductsSuccess = (data) => ({
  type: ActionTypes.ONLINE_PRODUCTS_SUCCESS,
  payload: {
    onlineProducts: data.res,
    offset: data.offset,
  },
});

export const onlineProductsFailure = () => ({
  type: ActionTypes.ONLINE_PRODUCTS_FAILURE,
});

export const categoryProductSearchRequest = (
  offset, limit, searchText, categoryId,
  storeId, onSuccess, cancelToken,
) => ({
  type: ActionTypes.CATEGORY_PRODUCT_SEARCH_REQUEST,
  payload: {
    offset,
    limit,
    searchText,
    categoryId,
    storeId,
    onSuccess,
    cancelToken,
  },
});

export const categoryProductSearchSuccess = (data) => ({
  type: ActionTypes.CATEGORY_PRODUCT_SEARCH_SUCCESS,
  payload: {
    categorySearchProducts: data.res,
    offset: data.offset,
  },
});

export const categoryProductSearchFailure = () => ({
  type: ActionTypes.CATEGORY_PRODUCT_SEARCH_FAILURE,
});

export const popularProductsRequest = (
  offset, limit, searchText, categoryId,
  storeId, onSuccess, cancelToken,
) => ({
  type: ActionTypes.POPULAR_PRODUCTS_REQUEST,
  payload: {
    offset,
    limit,
    searchText,
    categoryId,
    storeId,
    onSuccess,
    cancelToken,
  },
});

export const popularProductsSuccess = (data) => ({
  type: ActionTypes.POPULAR_PRODUCTS_SUCCESS,
  payload: {
    popularProducts: data.res,
    offset: data.offset,
  },
});

export const popularProductsFailure = () => ({
  type: ActionTypes.POPULAR_PRODUCTS_FAILURE,
});

export const orderDetailsRequest = (
  orderId, onSuccess, cancelToken, isFirstOrderView,
) => ({
  type: ActionTypes.ORDER_DETAILS_REQUEST,
  payload: {
    orderId,
    onSuccess,
    cancelToken,
    isFirstOrderView,
  },
});

export const orderDetailsSuccess = (data) => ({
  type: ActionTypes.ORDER_DETAILS_SUCCESS,
  payload: {
    orderDetails: data.res,
  },
});

export const orderDetailsFailure = () => ({
  type: ActionTypes.ORDER_DETAILS_FAILURE,
});

export const orderPatchRequest = (orderId, reason = '', onSuccess) => ({
  type: ActionTypes.ORDER_PATCH_REQUEST,
  payload: {
    orderId,
    reason,
    onSuccess,
  },
});

export const orderPatchSuccess = (data) => ({
  type: ActionTypes.ORDER_PATCH_SUCCESS,
  payload: {
    orderDetails: data.res,
  },
});

export const orderPatchFailure = () => ({
  type: ActionTypes.ORDER_PATCH_FAILURE,
});

export const ordersCreateRequest = (cartData, onSuccess, onFailure) => ({
  type: ActionTypes.ORDERS_CREATE_REQUEST,
  payload: {
    cartData,
    onSuccess,
    onFailure,
  },
});

export const ordersCreateSuccess = () => ({
  type: ActionTypes.ORDERS_CREATE_SUCCESS,
});

export const ordersCreateFailure = () => ({
  type: ActionTypes.ORDERS_CREATE_FAILURE,
});

export const ordersLoadRequest = (
  nextUrl, onSuccess, cancelToken,
) => ({
  type: ActionTypes.ORDERS_LOAD_REQUEST,
  payload: {
    nextUrl,
    onSuccess,
    cancelToken,
  },
});

export const ordersLoadSuccess = (data) => ({
  type: ActionTypes.ORDERS_LOAD_SUCCESS,
  payload: {
    orders: data.res,
    nextUrl: data.nextUrl,
  },
});

export const ordersLoadFailure = () => ({
  type: ActionTypes.ORDERS_LOAD_FAILURE,
});

export const pastProductsRequest = (offset, limit, onSuccess, cancelToken) => ({
  type: ActionTypes.PAST_PRODUCTS_REQUEST,
  payload: {
    offset,
    limit,
    onSuccess,
    cancelToken,
  },
});

export const pastProductsSuccess = (data) => ({
  type: ActionTypes.PAST_PRODUCTS_SUCCESS,
  payload: {
    pastProducts: data.res,
    offset: data.offset,
  },
});

export const pastProductsFailure = () => ({
  type: ActionTypes.PAST_PRODUCTS_FAILURE,
});

export const previousOrderProductsRequest = (
  storeId, onSuccess, cancelToken,
) => ({
  type: ActionTypes.PREVIOUS_ORDER_PRODUCTS_REQUEST,
  payload: {
    storeId,
    onSuccess,
    cancelToken,
  },
});

export const previousOrderProductsSuccess = (data) => ({
  type: ActionTypes.PREVIOUS_ORDER_PRODUCTS_SUCCESS,
  payload: {
    previousOrderProducts: data.res,
  },
});

export const previousOrderProductsFailure = () => ({
  type: ActionTypes.PREVIOUS_ORDER_PRODUCTS_FAILURE,
});

export const productCategoryRequest = (cancelToken, storeId, onSuccess = null) => ({
  type: ActionTypes.PRODUCT_CATEGORY_REQUEST,
  payload: {
    cancelToken,
    storeId,
    onSuccess,
  },
});

export const productCategorySuccess = (data) => ({
  type: ActionTypes.PRODUCT_CATEGORY_SUCCESS,
  payload: {
    categories: data.res,
  },
});

export const productCategoryFailure = () => ({
  type: ActionTypes.PRODUCT_CATEGORY_FAILURE,
});

export const storeCategoriesRequest = (cancelToken) => ({
  type: ActionTypes.STORE_CATEGORIES_REQUEST,
  payload: {
    cancelToken,
  },
});

export const storeCategoriesSuccess = (data) => ({
  type: ActionTypes.STORE_CATEGORIES_SUCCESS,
  payload: {
    storeCategories: data.res,
  },
});

export const storeCategoriesFailure = () => ({
  type: ActionTypes.STORE_CATEGORIES_FAILURE,
});

export const profileEditRequest = (profile, onSuccess, cancelToken) => ({
  type: ActionTypes.PROFILE_EDIT_REQUEST,
  payload: {
    profile,
    onSuccess,
    cancelToken,
  },
});

export const profileEditSuccess = (data) => ({
  type: ActionTypes.PROFILE_EDIT_SUCCESS,
  payload: {
    profile: data.res,
  },
});

export const profileEditFailure = () => ({
  type: ActionTypes.PROFILE_EDIT_FAILURE,
});

export const profileLoadRequest = (onSuccess) => ({
  type: ActionTypes.PROFILE_LOAD_REQUEST,
  payload: {
    onSuccess,
  },
});

export const profileLoadSuccess = (data) => ({
  type: ActionTypes.PROFILE_LOAD_SUCCESS,
  payload: {
    profile: data.res,
  },
});

export const profileLoadFailure = () => ({
  type: ActionTypes.PROFILE_LOAD_FAILURE,
});

export const resetRequestStatus = (requests, fields) => ({
  type: ActionTypes.RESET_REQUEST_STATUS,
  payload: {
    requests,
    fields,
  },
});

export const referralCodeRequest = (couponCode, onSuccess, onFailure) => ({
  type: ActionTypes.REFERRAL_CODE_REQUEST,
  payload: {
    couponCode,
    onSuccess,
    onFailure,
  },
});

export const referralCodeSuccess = (data) => ({
  type: ActionTypes.REFERRAL_CODE_SUCCESS,
  payload: {
    cartDetails: data.res,
  },
});

export const referralCodeFailure = () => ({
  type: ActionTypes.REFERRAL_CODE_FAILURE,
});

export const rewardLoadRequest = (params = {}, cancelToken, onSuccess, onFailure, nextUrl) => ({
  type: ActionTypes.REWARD_LOAD_REQUEST,
  payload: {
    params, cancelToken, onSuccess, onFailure, nextUrl,
  },
});

export const rewardLoadSuccess = ({ res, nextUrl }) => ({
  type: ActionTypes.REWARD_LOAD_SUCCESS,
  payload: {
    rewards: res,
    nextUrl,
  },
});

export const rewardLoadFailure = () => ({
  type: ActionTypes.REWARD_LOAD_FAILURE,
});

export const storeAuxiliaryRequest = (storeId, cancelToken, onSuccess) => ({
  type: ActionTypes.STORE_AUXILIARY_REQUEST,
  payload: {
    storeId,
    cancelToken,
    onSuccess,
  },
});

export const storeAuxiliarySuccess = (data) => ({
  type: ActionTypes.STORE_AUXILIARY_SUCCESS,
  payload: {
    storeDetails: data.res,
    storeId: data.storeId,
  },
});

export const storeAuxiliaryFailure = () => ({
  type: ActionTypes.STORE_AUXILIARY_FAILURE,
  payload: {

  },
});

export const spendOffersLoadRequest = (offset, limit, onSuccess, cancelToken, storeId) => ({
  type: ActionTypes.SPEND_OFFERS_LOAD_REQUEST,
  payload: {
    offset,
    limit,
    onSuccess,
    cancelToken,
    storeId,
  },
});

export const spendOffersLoadSuccess = (data) => ({
  type: ActionTypes.SPEND_OFFERS_LOAD_SUCCESS,
  payload: {
    spendOffers: data.res,
    offset: data.offset,
  },
});

export const spendOffersLoadFailure = () => ({
  type: ActionTypes.SPEND_OFFERS_LOAD_FAILURE,
});

export const spendOfferRequest = (spendOfferId, apply, onSuccess, onFailure) => ({
  type: ActionTypes.SPEND_OFFER_REQUEST,
  payload: {
    spendOfferId,
    apply,
    onSuccess,
    onFailure,
  },
});

export const spendOfferSuccess = (data) => ({
  type: ActionTypes.SPEND_OFFER_SUCCESS,
  payload: {
    cartDetails: data.res,
  },
});

export const spendOfferFailure = () => ({
  type: ActionTypes.SPEND_OFFER_FAILURE,
});

export const storesLoadRequest = (
  onSuccess, cancelToken, limit, offset, filterFlag, city, locality, storeTypes,
) => ({
  type: ActionTypes.STORES_LOAD_REQUEST,
  payload: {
    onSuccess,
    cancelToken,
    filterFlag,
    city,
    locality,
    storeTypes,
    limit,
    offset,
  },
});

export const storesLoadSuccess = (data) => ({
  type: ActionTypes.STORES_LOAD_SUCCESS,
  payload: {
    stores: data.res,
  },
});

export const storesLoadFailure = () => ({
  type: ActionTypes.STORES_LOAD_FAILURE,
});

export const onlineStoresLoadRequest = (
  onSuccess, cancelToken, limit, offset, filterFlag, city, locality, storeType,
) => ({
  type: ActionTypes.ONLINE_STORES_LOAD_REQUEST,
  payload: {
    onSuccess,
    cancelToken,
    filterFlag,
    city,
    locality,
    storeType,
    limit,
    offset,
  },
});

export const onlineStoresLoadSuccess = (data) => ({
  type: ActionTypes.ONLINE_STORES_LOAD_SUCCESS,
  payload: {
    onlineStores: data.res,
  },
});

export const onlineStoresLoadFailure = () => ({
  type: ActionTypes.ONLINE_STORES_LOAD_FAILURE,
});

export const seoMetadataLoadRequest = (
  cancelToken, city, locality, storeType,
) => ({
  type: ActionTypes.SEO_METADATA_LOAD_REQUEST,
  payload: {
    cancelToken,
    city,
    locality,
    storeType,
  },
});

export const seoMetadataLoadSuccess = (data) => ({
  type: ActionTypes.SEO_METADATA_LOAD_SUCCESS,
  payload: {
    seoMetadata: data.res,
  },
});

export const seoMetadataLoadFailure = () => ({
  type: ActionTypes.SEO_METADATA_LOAD_FAILURE,
});

export const bestRatedStoresRequest = (
  onSuccess, cancelToken, limit, offset, filterFlag, city, locality, storeTypes,
) => ({
  type: ActionTypes.BEST_RATED_STORES_REQUEST,
  payload: {
    onSuccess,
    cancelToken,
    filterFlag,
    city,
    locality,
    storeTypes,
    limit,
    offset,
  },
});

export const bestRatedStoresSuccess = (data) => ({
  type: ActionTypes.BEST_RATED_STORES_SUCCESS,
  payload: {
    bestRatedStores: data.res,
  },
});

export const bestRatedStoresFailure = () => ({
  type: ActionTypes.BEST_RATED_STORES_FAILURE,
});

export const topPicksShopsLoadRequest = (
  onSuccess, cancelToken, filterFlag, city, locality, storeTypes,
) => ({
  type: ActionTypes.TOP_PICKS_SHOPS_REQUEST,
  payload: {
    onSuccess,
    cancelToken,
    filterFlag,
    city,
    locality,
    storeTypes,
  },
});

export const topPicksShopsLoadSuccess = (data) => ({
  type: ActionTypes.TOP_PICKS_SHOPS_SUCCESS,
  payload: {
    topPicksShops: data.res,
  },
});

export const topPicksShopsLoadFailure = () => ({
  type: ActionTypes.TOP_PICKS_SHOPS_FAILURE,
});

export const storeLikeRequest = (storeId, status, cancelToken, onSuccess, onFailure) => ({
  type: ActionTypes.STORE_LIKE_REQUEST,
  payload: {
    storeId,
    status,
    cancelToken,
    onSuccess,
    onFailure,
  },
});

export const storeLikeSuccess = (data) => ({
  type: ActionTypes.STORE_LIKE_SUCCESS,
  payload: {
    storeId: data.storeId,
    status: data.status,
  },
});

export const storeLikeFailure = () => ({
  type: ActionTypes.STORE_LIKE_FAILURE,
});

export const storeWisePrevOrderProductsRequest = (cancelToken) => ({
  type: ActionTypes.STORE_WISE_PREV_ORDER_PRODUCTS_REQUEST,
  payload: {
    cancelToken,
  },
});

export const storeWisePrevOrderProductsFailure = () => ({
  type: ActionTypes.STORE_WISE_PREV_ORDER_PRODUCTS_FAILURE,
});

export const storeWisePrevOrderProductsSuccess = (data) => ({
  type: ActionTypes.STORE_WISE_PREV_ORDER_PRODUCTS_SUCCESS,
  payload: {
    storeWisePrevOrderProducts: data.res,
  },
});

export const toggleLogin = () => ({
  type: ActionTypes.TOGGLE_LOGIN,
});

export const toggleGuestLocation = (
  toggleGuestLocationPopup, isMandatoryGuestLocationPopup = false,
) => ({
  type: ActionTypes.TOGGLE_GUEST_LOCATION,
  payload: {
    toggleGuestLocationPopup,
    isMandatoryGuestLocationPopup,
  },
});

export const toggleMultiStoreModal = (data) => ({
  type: ActionTypes.TOGGLE_MULTI_STORE_CHECKOUT,
  payload: {
    multiStoreCheckoutErrorMsg: data.errorMsg,
    productId: data.productId,
    product: data.product,
    productInfo: data.productInfo,
  },
});

export const toggleGlobalSearch = () => ({
  type: ActionTypes.TOGGLE_GLOBAL_SEARCH,
});

export const cartNullRequest = () => ({
  type: ActionTypes.CART_NULL_REQUEST,
});

export const toggleManageLocation = () => ({
  type: ActionTypes.TOGGLE_MANAGE_LOCATION,
});

export const toggleScratchCard = (scratchCard) => ({
  type: ActionTypes.TOGGLE_SCRATCH_CARD,
  payload: {
    scratchCard,
  },
});

export const topSavingOfferRequest = (offset, limit, onSuccess, cancelToken, storeId) => ({
  type: ActionTypes.TOP_SAVING_OFFER_REQUEST,
  payload: {
    offset,
    limit,
    onSuccess,
    cancelToken,
    storeId,
  },
});

export const topSavingOfferSuccess = (data) => ({
  type: ActionTypes.TOP_SAVING_OFFER_SUCCESS,
  payload: {
    offers: data.res,
    offset: data.offset,
  },
});

export const topSavingOfferFailure = () => ({
  type: ActionTypes.TOP_SAVING_OFFER_FAILURE,
});

export const monthlySamanSectionRequest = (offset, limit, onSuccess, cancelToken) => ({
  type: ActionTypes.MONTHLY_SAMAN_SECTION_REQUEST,
  payload: {
    offset,
    limit,
    onSuccess,
    cancelToken,
  },
});

export const monthlySamanSectionSuccess = (data) => ({
  type: ActionTypes.MONTHLY_SAMAN_SECTION_SUCCESS,
  payload: {
    offers: data.res,
    offset: data.offset,
  },
});

export const monthlySamanSectionFailure = () => ({
  type: ActionTypes.MONTHLY_SAMAN_SECTION_FAILURE,
});

export const unlockRewardRequest = (scratchCardId) => ({
  type: ActionTypes.UNLOCK_REWARD_REQUEST,
  payload: {
    scratchCardId,
  },
});

export const unlockRewardSuccess = (data) => ({
  type: ActionTypes.UNLOCK_REWARD_SUCCESS,
  payload: {
    reward: data.res,
  },
});

export const unlockRewardFailure = () => ({
  type: ActionTypes.UNLOCK_REWARD_FAILURE,
});

export const unusedOfferCountRequest = (onlyGlobal, cancelToken, onSuccess, onFailure) => ({
  type: ActionTypes.UNUSED_OFFER_COUNT_REQUEST,
  payload: {
    onlyGlobal,
    cancelToken,
    onSuccess,
    onFailure,
  },
});

export const unusedOfferCountSuccess = (data) => ({
  type: ActionTypes.UNUSED_OFFER_COUNT_SUCCESS,
  payload: {
    unusedOfferCount: data.res,
  },
});

export const unusedOfferCountFailure = () => ({
  type: ActionTypes.UNUSED_OFFER_COUNT_FAILURE,
});

export const updateSelectedAddress = (address, onSuccess) => ({
  type: ActionTypes.UPDATE_ADDRESS,
  payload: {
    address,
    onSuccess,
  },
});

export const updateCartItems = (cartItems) => ({
  type: ActionTypes.UPDATE_CART_ITEMS,
  payload: {
    cartItems,
  },
});

export const updateNotifyProductItems = (outOfStockNotifyProduct) => ({
  type: ActionTypes.UPDATE_NOTIFY_PRODUCTS_DETAILS,
  payload: {
    outOfStockNotifyProduct,
  },
});

export const updateCoachmark = (coachmark) => ({
  type: ActionTypes.UPDATE_COACHMARK,
  payload: {
    coachmark,
  },
});

export const updateCustomer = (customer) => ({
  type: ActionTypes.UPDATE_CUSTOMER,
  payload: {
    customer,
  },
});

export const valueOfferRequest = (valueOfferId, apply, onSuccess, onFailure) => ({
  type: ActionTypes.VALUE_OFFER_REQUEST,
  payload: {
    valueOfferId,
    apply,
    onSuccess,
    onFailure,
  },
});

export const valueOfferSuccess = (data) => ({
  type: ActionTypes.VALUE_OFFER_SUCCESS,
  payload: {
    cartDetails: data.res,
  },
});

export const valueOfferFailure = () => ({
  type: ActionTypes.VALUE_OFFER_FAILURE,
});

export const applyValueOfferRequest = (cartId) => ({
  type: ActionTypes.APPLY_VALUE_OFFER_REQUEST,
  payload: {
    cId: cartId,
  },
});

export const applyValueOfferFailure = () => ({
  type: ActionTypes.APPLY_VALUE_OFFER_FAILURE,
});

export const referralCodeGetReq = () => ({
  type: ActionTypes.REFERRAL_CODE_GET_REQUEST,
  payload: {},
});

export const referralCodeGetSuccess = (data) => ({
  type: ActionTypes.REFERRAL_CODE_GET_SUCCESS,
  payload: {
    referralCode: data.res.referral_code,
  },
});

export const referralCodeGetFailure = () => ({
  type: ActionTypes.REFERRAL_CODE_GET_FAILURE,
});
