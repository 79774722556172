/* eslint-disable jsx-a11y/no-static-element-interactions */
// eslint-disable-next-line jsx-a11y/no-static-element-interactions
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import {
  Button, Container, Row, Col,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Cart from 'app/pages/cart/Cart';
import GuestUserCart from 'app/pages/cart/GuestUserCart';
import * as Actions from '../../store/Actions';
import { Constants, Config, Storage } from '../../utilities';
import { Navbar, Svg } from '../../components/common';
import {
  goBack,
  isAlreadyLoggedIn, isAuthenticated, isGuestUser,
} from '../../utilities/Utils';
import * as screens from '../../events/screens';
import * as buttonActions from '../../events/buttonActions';
import events from '../../../clevertap/Event';
import { logButtonClick, logCTEvent, logPageLoad } from '../../events/Events';
import TopNavSearchBar from '../../components/common/TopNavSearchBar';
import { fbLogCustomEvent } from '../../../facebook/Facebook';

const { String, Color } = Constants;
const { topNav } = Config;

class TopMenu extends Component {
  constructor() {
    super();
    this.state = {
      isAccountOpen: false,
    };
  }

  componentDidMount = () => {
    window.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleClickOutside);
    document.body.style.overflow = 'auto';
  }

  handleClickOutside = (event) => {
    if (!(event.target.closest('.r__main_btn-wrapper'))) {
      this.handleCloseDropdown();
    }
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isAccountOpen: !prevState.isAccountOpen,
    }));
  };

  handleCloseDropdown = () => {
    this.setState(() => ({
      isAccountOpen: false,
    }));
  };

  handleChange = (value) => {
    const { history } = this.props;
    const { search } = history.location;
    const queryParam = QueryString.parse(search);
    if (value) {
      history.push({
        search: `?${QueryString.stringify({ ...queryParam, q: value })}`,
      });
    } else {
      delete queryParam.q;
      history.push({
        search: Object.entries(queryParam).length
          ? `?${QueryString.stringify(queryParam)}`
          : '',
      });
    }
  }

  render() {
    const {
      isMobile,
      language,
      address,
      customer,
      history,
      location,
      toggleLogin,
      toggleSideOverlay,
      cartItems,
      cartDetails,
      page,
      unusedOfferCount,
      toggleManageLocation,
      toggleGlobalSearch,
      guestCustomCartItems,
      openManageLocation,
      toggleAccountDropdown,
    } = this.props;
    let pageTitle = null;
    let showCartIcon = true;
    let state = {};

    const queryParams = QueryString.parse(history.location.search);
    let sname = '';
    let { sid } = queryParams;
    if (!sid && history.location.state) {
      sid = history.location.state.sid;
      sname = history.location.state.sname;
    }

    if (cartDetails?.storeItems && page === 'checkout-store-items') {
      const storeItem = cartDetails.storeItems.find((item) => item.store.code === sid);
      sname = storeItem.store.displayName;
    }

    if (isMobile) {
      switch (page) {
        case 'address':
          pageTitle = {
            displayText: String.SELECT_ADDRESS[language],
            backTo: '',
          };
          showCartIcon = false;
          break;
        case 'add-location':
          pageTitle = {
            displayText: String.ADD_LOCATION[language],
            backTo: '',
          };
          showCartIcon = false;
          break;
        case 'faq':
          pageTitle = {
            displayText: String.FAQS[language],
            backTo: '',
          };
          showCartIcon = false;
          break;
        case 'language':
          pageTitle = {
            displayText:
              !Storage.getLanguage()
                ? String.CHOOSE_LANGUAGE[language]
                : String.CHANGE_LANGUAGE[language],
            backTo: '/account',
          };
          showCartIcon = false;
          break;
        case 'cart':
          pageTitle = {
            displayText: String.CHECKOUT[language],
            backTo: '',
          };
          showCartIcon = false;
          break;
        case 'categories':
          pageTitle = {
            displayText: String.CATEGORIES[language],
            backTo: '',
          };
          break;
        case 'applicable-value-offers':
          pageTitle = {
            displayText: String.VALUE_OFFERS[language],
            backTo: '',
          };
          state = { fromValueOffer: true };
          showCartIcon = false;
          break;
        case 'checkout':
          pageTitle = {
            displayText: String.CHECKOUT[language],
            backTo: '/',
          };
          showCartIcon = false;
          break;
        case 'checkout-store-items':
          pageTitle = {
            displayText: sname,
            backTo: '/checkout',
          };
          showCartIcon = false;
          state = { fromCheckoutStoreItems: true };
          break;
        case 'refer-and-earn':
          pageTitle = {
            displayText: String.REFER_AND_EARN[language],
            backTo: '',
          };
          showCartIcon = false;
          break;
        case 'refer-friend-tnc':
          pageTitle = {
            displayText: String.TERMS_CONDITION[language],
            backTo: '',
          };
          showCartIcon = false;
          break;
        case 'edit-address':
          pageTitle = {
            displayText: String.EDIT_ADDRESS[language],
            backTo: '',
          };
          showCartIcon = false;
          break;
        case 'favourites':
          pageTitle = {
            displayText: String.FAVOURITES[language],
            backTo: '/account',
          };
          break;
        case 'global-search':
          pageTitle = {
            displayText: 'Search',
            backTo: '/',
          };
          break;
        case 'offers':
          pageTitle = {
            displayText: String.OFFERS[language],
            backTo: '/',
          };
          showCartIcon = false;
          break;
        case 'offers-combo':
          pageTitle = {
            displayText: String.ALL_COMBO_OFFERS[language],
            backTo: '',
          };
          break;
        case 'popular-products':
          pageTitle = {
            displayText: String.POPULAR[language],
            backTo: '',
          };
          break;
        case 'offers-coupon':
          pageTitle = {
            displayText: String.DISCOUNT_COUPONS[language],
            backTo: '',
          };
          break;
        case 'offers-discount':
          pageTitle = {
            displayText: String.TOP_SAVINGS_FOR_YOU[language],
            backTo: '',
          };
          break;
        case 'offers-spend':
          pageTitle = {
            displayText: String.VALUE_OFFERS[language],
            backTo: '',
          };
          break;
        case 'offers-reward':
          pageTitle = {
            displayText: String.LOVELOCAL_REWARDS[language],
            backTo: '',
          };
          break;
        case 'reward-coupon': {
          pageTitle = {
            displayText: String.OFFER[language],
            backTo: '',
          };
          showCartIcon = false;
          state = { fromRewardCoupon: true };
          break;
        }
        case 'orders':
          pageTitle = {
            displayText: String.ORDERS[language],
            backTo: '/account',
          };
          break;
        case 'order-details':
          pageTitle = {
            displayText: String.YOUR_ORDER[language],
            backTo: '/orders',
          };
          break;
        case 'past-products':
          pageTitle = {
            displayText: String.PAST_PRODUCTS[language],
            backTo: '',
          };
          break;
        case 'category-landing':
          pageTitle = {
            displayText: String.PRODUCTS[language],
            backTo: '',
          };
          break;
        case 'reorder':
          pageTitle = {
            displayText: String.REPEAT_YOUR_ORDER[language],
            backTo: '/orders?deliveryStatus=past',
          };
          break;
        case 'reviews':
          pageTitle = {
            displayText: String.ALL_REVIEWS[language],
            backTo: `/shops/${sid}`,
          };
          break;
        case 'shop-detail':
          pageTitle = {
            displayText: String.SHOP_DETAILS[language],
            backTo: '',
          };
          break;
        case 'shop-coupons':
          pageTitle = {
            displayText: String.SHOP_COUPONS[language],
            backTo: '/offers',
          };
          break;
        case 'product-details':
          pageTitle = {
            displayText: String.PRODUCT_DETAILS[language],
            backTo: '',
          };
          break;
        case 'order-feedback':
          pageTitle = {
            displayText: String.SUBMIT_FEEDBACK[language],
            backTo: '',
          };
          showCartIcon = false;
          break;
        case 'order-past-products':
          pageTitle = {
            displayText: String.PREVIOUSLY_ORDERED[language],
            backTo: '',
          };
          showCartIcon = true;
          break;
        case 'store-wise-order-past-products':
          pageTitle = {
            displayText: String.PREVIOUSLY_ORDERED[language],
            backTo: '',
          };
          showCartIcon = true;
          break;
        case 'max-savings':
          pageTitle = {
            displayText: 'Max Savings',
            backTo: '/offers',
          };
          showCartIcon = false;
          break;
        case 'bogo-offers':
          pageTitle = {
            displayText: 'Mega Combo Offers',
            backTo: '/offers',
          };
          showCartIcon = false;
          break;
        case 'trending':
          pageTitle = {
            displayText: 'Trending',
            backTo: '',
          };
          showCartIcon = false;
          break;
        case 'monthly-saman-list':
          pageTitle = {
            displayText: 'Monthly Samaan',
            backTo: '',
          };
          showCartIcon = false;
          break;
        case 'brands-listing':
          pageTitle = {
            displayText: 'Brand Products',
            backTo: '',
          };
          showCartIcon = false;
          break;
        case 'super-savings-coupons':
          pageTitle = {
            displayText: 'Super Savings',
            backTo: '/offers',
          };
          showCartIcon = false;
          break;
        case 'shops-category-products':
        case 'shops-top-saving-products':
        case 'shops-combo-offers':
        case 'shops-repeat-products':
          pageTitle = {
            displayText: sname || 'Store Details',
          };
          showCartIcon = true;
          break;
        default:
          break;
      }
    }

    const navElement = (item) => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <span
        className="d-flex align-items-center h-100 position-relative"
      >
        <span>
          &nbsp;
          {String[item.displayText][language]}
        </span>
        {
          item.id === 2
          && unusedOfferCount
          && unusedOfferCount.unusedSC + unusedOfferCount.unusedCoupon > 0
          && (
            <span
              className="red-dot-top bg-primary"
            />
          )
        }
      </span>
    );
    const navState = (link) => (
      `${location.pathname === link
        ? 'custom-border-bottom-primary' : 'custom-border-bottom'}`
    );
    let cartCount = Object.values(cartItems || {})
      .reduce((acc, cartProduct) => acc + cartProduct.quantity, 0);
    let itemTotal = Object.values(cartItems || {})
      .reduce((acc, cartProduct) => acc + (cartProduct.quantity * cartProduct.sellingPrice), 0);

    if (isGuestUser()) {
      const guestCustomItemsCnt = Object.values(guestCustomCartItems || {})
        .reduce((acc, customProduct) => acc + customProduct.quantity, 0);
      cartCount += guestCustomItemsCnt;

      const guestItemTotal = Object.values(cartItems || {})
        // eslint-disable-next-line max-len
        .reduce((acc, cartProduct) => acc + (cartProduct.quantity * cartProduct.productInfo.sellingPrice), 0);
      itemTotal = guestItemTotal;
    }
    return (
      <div
        className="h-100"
      >
        <Container
          fluid
          className={`h-100 px-lg-global-search
            ${openManageLocation && !address ? 'pointer-event-none' : ''}
          `}
        >
          <Row
            className="align-items-center h-100 mx-0"
            onClick={() => {
              if (openManageLocation) {
                toggleManageLocation();
              }
            }}
          >
            {
              isMobile ? (
                !pageTitle && (
                <Col
                  xs="auto"
                  as={Link}
                  to={{
                    pathname: '/',
                    state: {
                      scrollToTop: true,
                    },
                  }}
                  className="pl-0 pr-1 "
                  onClick={() => {
                    logButtonClick(
                      {
                        BUTTON: buttonActions.HOME_TAB,
                      },
                    );
                  }}
                >
                  <Svg
                    svg="logo-ll"
                    fill1={Color.primary}
                    fill2={Color.white}
                    width="4.2rem"
                  />
                </Col>
                )
              ) : (
                <Col
                  xs="auto"
                  as={Link}
                  to={{
                    pathname: '/',
                    state: {
                      scrollToTop: true,
                    },
                  }}
                  className="d-none d-lg-block cursor-pointer header-logo pl-0 pr-4"
                  onClick={() => {
                    logButtonClick(
                      {
                        BUTTON: buttonActions.HOME_TAB,
                      },
                    );
                  }}
                >
                  <Svg
                    svg="logo-ll"
                    fill1={Color.primary}
                    fill2={Color.white}
                    width="4.8rem"
                  />
                </Col>
              )
            }
            {
              isMobile && pageTitle && (
                <Col
                  className="px-0 h-100 d-flex align-items-center"
                >
                  <Row
                    className="align-items-center mx-0 h-100 w-100"
                  >
                    {
                      page === 'language' && !Storage.getLanguage()
                        ? ''
                        : (
                          <Col
                            xs="auto"
                            className="px-0 h-100"
                          >
                            <Button
                              variant="link"
                              className="pl-1 pr-4 h-100 d-flex align-items-center"
                              onClick={() => {
                                if (pageTitle.backTo) {
                                  history.push(pageTitle.backTo, state);
                                  return;
                                }
                                goBack(history);
                              }}
                            >
                              <Svg
                                svg="leftArrow"
                                fill={Color.primary}
                                width="1rem"
                              />
                            </Button>
                          </Col>
                        )
                    }
                    <Col
                      className="text-primary text-truncate px-0"
                    >
                      <div
                        className="h-100"
                      >
                        {pageTitle.displayText}
                      </div>
                    </Col>
                  </Row>
                </Col>
              )
            }
            {
              !(isMobile && pageTitle)
              && (
                <Col
                  {...(
                    isMobile ? {} : {
                      xs: 'auto',
                    }
                  )}
                  className="px-0 position-relative"
                >
                  <Row
                    className={` d-block ${
                      isMobile ? 'mx-3' : 'mx-0 mw-200p align-items-start'
                    }`}
                    onClick={() => {
                      fbLogCustomEvent('LocationButtonClick');
                      toggleManageLocation();
                    }}
                  >
                    <div className="d-flex align-items-center">
                      {!isMobile && (
                      <Col
                        xs="auto"
                        lg="auto"
                        className="p-0 d-flex"
                      >
                        <Svg
                          svg="location"
                          width="16px"
                          fill={Constants.Color.primary}
                        />
                      </Col>
                      )}
                      <Col
                        xs={24}
                        className={`${isMobile ? 'mw-120p ' : 'mw-150p line-height-24p'}
                          pl-1 text-truncate fs-6 font-weight-black text-primary`}
                      >
                        <span className="border-bottom border-primary">
                          {address ? address.addressType : 'Mumbai'}
                        </span>
                        {isMobile && (
                        <span className="mx-2">
                          <Svg
                            svg="arrowDown"
                            width="12px"
                            stroke={Constants.Color.primary}
                          />
                        </span>
                        )}
                      </Col>
                    </div>
                    <div className="d-flex align-items-center">
                      <Col
                        xs="auto"
                        lg="auto"
                        className={`${isMobile ? 'mw-120p ml-1' : 'mw-150p'} fs-6 text-truncate px-0`}
                      >
                        {address ? address.addressText : ''}
                      </Col>
                      {!isMobile && (
                        <Col
                          xs="auto"
                          lg="auto"
                          className="py-0 pl-1"
                        >
                          <Svg
                            svg="arrowDown"
                            width="12px"
                            stroke={Constants.Color.black}
                          />
                        </Col>
                      )}
                    </div>
                  </Row>
                </Col>
              )
            }
            <Col
              className="d-none d-lg-block px-4"
            >
              <TopNavSearchBar
                {...this.props}
                isGlobalSearch
                language={language}
                showCategories={!isMobile}
              />
            </Col>
            <Col
              xs="auto"
              className="h-100 d-none d-lg-block px-0"
            >
              <Navbar
                navElement={navElement}
                navState={navState}
                navConfig={topNav}
                toggleGlobalSearch={toggleGlobalSearch}
                classNames={{
                  navbar: 'h-100 d-flex align-items-center',
                  navLink: 'h-100 d-block text-decoration-none text-black mx-2 px-1',
                }}
                toggleLogin={toggleLogin}
                history={history}
              />
            </Col>
            <Col
              xs="auto"
              className="d-none d-lg-block px-0 r__acc_drop"
            >
              <button
                type="button"
                className="d-flex align-items-center text-black px-0 font-weight-normal mw-120p r__main_btn"
                onClick={toggleAccountDropdown}
              >
                &nbsp;
                <span
                  className="text-truncate"
                >
                  {customer && (customer.first_name || String.ACCOUNT[language])}
                </span>
                <span>
                  <Svg
                    svg="arrowDown"
                    width="16px"
                    stroke={Constants.Color.black}
                  />
                </span>
              </button>
            </Col>
            {
              isAuthenticated()
              && page === 'checkout-store-items'
              && (
                <Link
                  to={`/shops/${sid}`}
                  className="font-weight-bold text-white"
                >
                  {Constants.String.VISIT_SHOP[language].toUpperCase()}
                </Link>
              )
            }
            {
              (
                isAlreadyLoggedIn()
              )
                ? (
                  <>
                    {
                      (
                        page === 'refer-and-earn'
                      ) && (
                        <Col
                          xs="auto"
                          className="px-0 h-100 d-flex align-items-center"
                        >
                          <Link
                            to="/refer-friend-tnc"
                            className="py-1 px-3 px-lg-4 text-primary fs-5"
                          >
                            <b>T&C</b>
                          </Link>
                        </Col>
                      )
                    }
                  </>
                ) : ''
            }
            {
              showCartIcon && isMobile
                ? (
                  <Col
                    xs="auto"
                    className="px-0 position-relative h-100"
                  >
                    <Button
                      variant="link"
                      onClick={() => {
                        if (isGuestUser()) {
                          toggleLogin();
                          return;
                        }
                        logButtonClick(
                          {
                            BUTTON: buttonActions.REFER_AND_EARN,
                            SCREEN: 'Top Header',
                          },
                        );
                        history.push('/refer-and-earn');
                      }}
                      className="px-3 px-lg-4 h-100"
                    >
                      <span className="fs-1">
                        🤑
                      </span>
                    </Button>
                  </Col>
                ) : ''
            }
            {
              showCartIcon && !isMobile
                ? (
                  <Col
                    xs="auto"
                  >
                    <Button
                      variant="primary"
                      onClick={() => {
                        if (isGuestUser()) {
                          toggleSideOverlay(GuestUserCart);
                        } else {
                          logButtonClick(
                            {
                              BUTTON: buttonActions.CART_CLICKED,
                            },
                          );
                          logPageLoad(
                            {
                              Screen: screens.CHECKOUT,
                            },
                          );
                          logCTEvent(events.Checkout_Page_Launched, { Source: 'Web' });
                          logCTEvent(
                            events.Shopping_Cart_Viewed,
                            {
                              'Total Amount': itemTotal,
                              'No of products': cartCount,
                            },
                          );
                          toggleSideOverlay(Cart);
                        }
                      }}
                      className="d-flex border-radius-8 px-2"
                    >
                      {
                        cartCount
                          ? (
                            <div
                              className="desktop-cart-count bg-yellow text-primary"
                            >
                              {`${cartCount < 10 ? '0' : ''}${cartCount}`}
                            </div>
                          ) : ''
                      }
                      <span>
                        <Svg
                          svg="cart"
                          fill="none"
                          stroke={Color.white}
                          width="24px"
                        />
                      </span>
                      <span className="align-items-center px-2 mt-auto">
                        {
                          cartCount
                            ? (
                              Constants.String.CHECKOUT[language]
                            ) : (
                              Constants.String.CART[language]
                            )
                        }
                      </span>
                      <span className="align-items-center">
                        <Svg
                          svg="chevron"
                          fill={Constants.Color.white}
                          height="16px"
                          width="12px"
                        />
                      </span>
                    </Button>
                  </Col>
                ) : ''
            }
            {/* Sell-On-LL */}
            <Col
              xs="auto"
              className="pl-3 pr-0 nav-sell-on-ll cursor-pointer"
            >
              <a
                href="https://play.google.com/store/apps/details?id=mpaani.com.android.yodaapp"
                target="_block"
              >
                <div
                  className="d-flex justify-content-center align-items-center py-1 px-2 border-radius-8 bg-yellow"
                >
                  <span>
                    <Svg
                      svg="logo"
                      fill={Color.violet}
                      stroke={Color.white}
                      strokeWidth="0px"
                      width="24px"
                    />
                  </span>
                  &nbsp;
                  <span
                    className="fs-6 text-black font-weight-bold"
                  >
                    {Constants.String.SELL_ON_LOVELOCAL[language]}
                  </span>
                </div>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  unusedOfferCount: state.main.unusedOfferCount,
  cartDetails: state.main.cartDetails,
  openManageLocation: state.main.openManageLocation,
});

const mapDispatchToProps = (dispatch) => ({
  toggleLogin: () => {
    dispatch(Actions.toggleLogin());
  },
  toggleGuestLocation: (toggleGuestLocationPopup) => {
    dispatch(Actions.toggleGuestLocation(toggleGuestLocationPopup));
  },
  toggleManageLocation: () => {
    dispatch(Actions.toggleManageLocation());
  },
  toggleGlobalSearch: () => {
    dispatch(Actions.toggleGlobalSearch());
  },
});

TopMenu.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  address: PropTypes.shape({
    addressText: PropTypes.string,
    addressType: PropTypes.string,
    isDummy: PropTypes.bool,
  }),
  cartItems: PropTypes.shape({
    productInfo: PropTypes.shape({}),
  }),
  guestCustomCartItems: PropTypes.shape({}),
  cartDetails: PropTypes.shape({
    storeItems: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  customer: PropTypes.shape({
    first_name: PropTypes.string,
  }),
  unusedOfferCount: PropTypes.shape({
    unusedSC: PropTypes.number,
    unusedCoupon: PropTypes.number,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
    length: PropTypes.number,
    location: PropTypes.shape({
      search: PropTypes.string,
      state: PropTypes.shape({
        sid: PropTypes.string,
        sname: PropTypes.string,
      }),
    }),
    goBack: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  toggleLogin: PropTypes.func.isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  toggleGlobalSearch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      storeId: PropTypes.string,
      shopURL: PropTypes.string,
    }),
  }).isRequired,
  performGuestLogin: PropTypes.func.isRequired,
  toggleGuestLocation: PropTypes.func.isRequired,
  toggleManageLocation: PropTypes.func.isRequired,
  // mSiteGlobalSearch: PropTypes.bool.isRequired,
  openManageLocation: PropTypes.bool.isRequired,
  toggleAccountDropdown: PropTypes.func.isRequired,
};

TopMenu.defaultProps = {
  address: null,
  customer: null,
  cartItems: null,
  cartDetails: null,
  unusedOfferCount: null,
  guestCustomCartItems: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);
